<template>
  <span :style="{ height, computedWidth }" class="skeleton" :class="className">
    <slot />
  </span>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    maxWidth: {
      type: Number,
      default: 100,
    },
    minWidth: {
      type: Number,
      default: 80,
    },
    className: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: null,
    },
  },
  computed: {
    computedWidth() {
      return (
        this.width ||
        `${Math.floor(
          Math.random() * (this.maxWidth - this.minWidth) + this.minWidth,
        )}%`
      );
    },
  },
});
</script>
